import * as Messages from './messages'
import { mergeLocales } from './utils/useLocales'

export default defineI18nConfig(() => {
  const messages = mergeLocales([Messages])

  return {
    locale: 'en',
    fallbackLocale: 'en',
    fallbackWarn: false,
    messages
  }
})
